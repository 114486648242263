import { render, staticRenderFns } from "./template.vue?vue&type=template&id=bf5969ea&scoped=true&"
import script from "@/Views/Global/UserProfile/component.ts?vue&type=script&lang=ts&"
export * from "@/Views/Global/UserProfile/component.ts?vue&type=script&lang=ts&"
import style0 from "@/Views/Global/UserProfile/style.scss?vue&type=style&index=0&id=bf5969ea&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bf5969ea",
  null
  
)

export default component.exports