// Classes
import { ActionField } from '@/Helpers/Components/DataTable/ActionField'

// Namespace Export
export namespace ActionFields {
	export const Delete = [
		new ActionField('actions', 'Acciones')
			.addItem('delete', 'icon').setIcon('trash-alt').setPermission('ACTION_DELETE').setVariant('red')
	]

	export const Edit = [
		new ActionField('actions', 'Acciones')
			.addItem('edit', 'icon').setIcon('pen').setPermission('ACTION_EDIT').setVariant('green')
	]
	
	export const EditDelete = [
		new ActionField('actions', 'Acciones')
			.addItem('edit', 'icon').setIcon('pen').setPermission('ACTION_EDIT').setVariant('green')
			.addItem('delete', 'icon').setIcon('trash-alt').setPermission('ACTION_DELETE').setVariant('red')
	]

	export const EditView = [
		new ActionField('actions', 'Acciones')
			.addItem('edit', 'icon').setIcon('pen').setPermission('ACTION_EDIT').setVariant('green')
			.addItem('view', 'icon').setIcon('th-list').setPermission('ACTION_VIEW').setVariant('blue')
	]
	
	export const Search = [
		new ActionField('actions', 'Acciones')
			.addItem('view', 'icon').setIcon('search').setPermission('ACTION_VIEW').setVariant('blue')
	]
	
	export const SearchPDF = [
		new ActionField('actions', 'Acciones')
			.addItem('view', 'icon').setIcon('search').setPermission('ACTION_VIEW').setVariant('blue')
			.addItem('pdf', 'icon').setIcon('file-pdf').setPermission('ACTION_PDF').setVariant('red')
	]
}